import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileVideo } from "react-icons/fa";

import FY24_25 from '../../assests/sgPdf/Annual Report/j. FY 2024-25/30th annual report of the company.pdf';
import FY24_25a from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Proceedings of the 30th Annual General Meeting-SGFL.pdf';
import FY24_25b from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Voting Result of 30th Annual General Meeting.pdf';
import FY24_25c from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Postal Ballot Results 05.10.2024.pdf';
import FY24_25d from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Scrutnizer Report_31.12.2024.pdf';
import FY23_24 from '../../assests/sgPdf/Annual Report/i. FY 2023-24/29th Annual Report.pdf';
import FY22_23 from '../../assests/sgPdf/Annual Report/ii. FY 2022-23/28th Annual Report of MSL.pdf';
import FY21_22 from '../../assests/sgPdf/Annual Report/iii. FY 2021-22/31.03.2022.pdf';
import FY21_22a from '../../assests/sgPdf/Annual Report/iii. FY 2021-22/27th Annual Report of MSL.pdf';
import FY20_21 from '../../assests/sgPdf/Annual Report/iv. FY 2020-21/31.03.2021.pdf';
import FY19_20 from '../../assests/sgPdf/Annual Report/v. FY 2019-20/31.03.2020.pdf';
import FY18_19 from '../../assests/sgPdf/Annual Report/vi. FY 2018-19/31.03.2019.pdf';
import FY17_18 from '../../assests/sgPdf/Annual Report/vii. FY 2017-18/31.03.2018.pdf';
import FY16_17 from '../../assests/sgPdf/Annual Report/viii. FY 2016-17/31.03.2017.pdf';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const AnnualReport = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [selectedOption, setSelectedOption] = useState("option9"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>


            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>AGM/ EGM/ Postal Ballot</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>AGM/ EGM/ Postal Ballot</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option9">FY 2024-25</option>
                                <option value="option8">FY 2023-24</option>
                                <option value="option1">FY 2022-23</option>
                                <option value="option2">FY 2021-22</option>
                                <option value="option3">FY 2020-21</option>
                                <option value="option4">FY 2019-20</option>
                                <option value="option5">FY 2018-19</option>
                                <option value="option6">FY 2017-18</option>
                                <option value="option7">FY 2016-17</option>
                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                        {selectedOption === "option9" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25} target='blank'><h6>30th Annual Report of the Company</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25a} target='blank'><h6>Proceedings of the 30th Annual General Meeting-SGFL</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25b} target='blank'><h6>Voting Result of 30th Annual General Meeting</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25c} target='blank'><h6>Postal Ballot Results for the FY 2024-25</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25d} target='blank'><h6>Scrutnizer Report_31.12.2024</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaFileVideo size={25} style={{ color: 'red' }} />
                                    <a  onClick={handleShow}><h6>Video recording of AGM

</h6></a>
                                </div>

                            </>

                        )}
                        
                        

      
                        
                        {selectedOption === "option8" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY23_24} target='blank'><h6>29th Annual Report of SGFL.pdf</h6></a>
                                </div>

                            </>

                        )}
                        {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY22_23} target='blank'><h6>28th Annual Report of MSL.pdf</h6></a>
                                </div>

                            </>

                        )}
                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21_22} target='blank'><h6>31.03.2022.pdf</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21_22a} target='blank'><h6>27th Annual Report of MSL</h6></a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY20_21} target='blank'><h6>31.03.2021.pdf</h6></a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option4" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY19_20} target='blank'><h6>31.03.2020.pdf</h6></a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option5" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY18_19} target='blank'><h6>31.03.2019.pdf</h6></a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option6" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY17_18} target='blank'><h6>31.03.2018.pdf</h6></a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option7" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY16_17} target='blank'><h6>31.03.2017.pdf</h6></a>
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </section>

            <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body><iframe width="100%" height="400" src="https://sgpdf.s3.ap-south-1.amazonaws.com/sg-pdf/CDSL-SG_FINSERVE_LIMITED_24Sep_24.mp4"></iframe></Modal.Body>
        
      </Modal>

            <Footer />


        </>
    )
}

export default AnnualReport