import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';
import fy25 from '../../assests/sgPdf/Shereholding/fy25/Shareholding Pattern March 2024.pdf';
import fy25a from '../../assests/sgPdf/Shereholding/fy25/Shareholding Pattern as on June 2024.pdf';
import fy25b from '../../assests/sgPdf/Shereholding/fy25/Shareholding Pattern as on September 2024.pdf';
import fy25c from '../../assests/sgPdf/Shereholding/fy25/Shareholding Pattern as on December 2024.pdf';
import fy24 from '../../assests/sgPdf/Shereholding/fy24/Shareholding Pattern SGFIN 31.12.2023.pdf';
import fy24a from '../../assests/sgPdf/Shereholding/fy24/March-2023-SHP.pdf';
import fy24b from '../../assests/sgPdf/Shereholding/fy24/June 2023-SHP.pdf';
import fy24c from '../../assests/sgPdf/Shereholding/fy24/Sept 2023-SHP.pdf';
import fy23a from '../../assests/sgPdf/Shereholding/fy23/Shareholding+Pattern+SGFIN+31.12.2022.pdf';
import fy23b from '../../assests/sgPdf/Shereholding/fy23/Shareholding+Pattern+Pre+10.12.2022.pdf';
import fy23c from '../../assests/sgPdf/Shereholding/fy23/Shareholding+Pattern+Post+10.12.2022.pdf';
import fy23d from '../../assests/sgPdf/Shereholding/fy23/01cfebb84c_Shareholding+Pattern+MSL.pdf';
import fy23e from '../../assests/sgPdf/Shereholding/fy23/Revised+Post+Shareholding+Pattern+16.09.2022+Final.pdf';
import fy23f from '../../assests/sgPdf/Shereholding/fy23/Pre+Shareholding+Pattern+16.09.2022.pdf';
import fy23g from '../../assests/sgPdf/Shereholding/fy23/121d5d5c74_regulation-31(b)-30062022-200+pdf.pdf';
import fy23h from '../../assests/sgPdf/Shereholding/fy23/Shareholding Pattern SGFIN 31.03.2023.pdf';

import fy22a from '../../assests/sgPdf/Shereholding/fy22/8e3c545b21_msl+shareholding+pattern+31.03.2022.pdf';
import fy22b from '../../assests/sgPdf/Shereholding/fy22/eec2b5f7a9_MSL+Shareholding+Pattern+dec+2021.pdf';
import fy22c from '../../assests/sgPdf/Shereholding/fy22/803a1afdff_REG+31+Shareholding+Pattern+MSL+Sep+2021.pdf';
import fy22f from '../../assests/sgPdf/Shereholding/fy22/cda932673a_MSL+Sharehlding+Pattern+June+2021.pdf';

import fy21a from '../../assests/sgPdf/Shereholding/fy21/83854d5ee8_Msl+Shareholding+pattern+PDF+31.03.2021.pdf';
import fy21b from '../../assests/sgPdf/Shereholding/fy21/6f8e7b8252_MSL+Shareholding+pattern+for+Quater+ended+December+2020.pdf';
import fy21c from '../../assests/sgPdf/Shereholding/fy21/8bf6f0ca4f_MSL+Shareholding+pattern+September+2020.pdf';

import fy20a from '../../assests/sgPdf/Shereholding/fy20/d222fc04a0_MSL+Shareholding+Pattern+for+the+Year+Ended+March+2020.pdf';
import fy20b from '../../assests/sgPdf/Shereholding/fy20/244b0021d5_MSL+Shareholding+Pattern+Dec+2019.pdf';
import fy20c from '../../assests/sgPdf/Shereholding/fy20/793e362f89_shareholding+pattern+website.pdf';
import fy20d from '../../assests/sgPdf/Shereholding/fy20/3f776ccfda_Shareholding+Pattern+MSL+quater+ended+June+2019.pdf';

import fy19a from '../../assests/sgPdf/Shereholding/fy19/08e6dff5c7_31+march+2019+Regulation+31(b).pdf';
import fy19b from '../../assests/sgPdf/Shereholding/fy19/187fd10c9c_Shareholding+Pattern+MSL.pdf';
import fy19d from '../../assests/sgPdf/Shereholding/fy19/sgfinserv-s3-ap-south-1-amazonaws-com-pdf-Share-Holding-Pattern-bebc7cb992_SHAREHOLDING-MSL-21-07-html.pdf';

import fy18a from '../../assests/sgPdf/Shereholding/fy18/sgfinserv-s3-ap-so....pdf';
import fy18b from '../../assests/sgPdf/Shereholding/fy18/sgfinserv-s3-ap-south-1-amazonaws-com-pdf-Share-Holding-Pattern-4d58c7efda_msl-31-12-17-html.pdf';
import fy18c from '../../assests/sgPdf/Shereholding/fy18/sgfinserv-s3-ap-so... (1).pdf';

import fy17a from '../../assests/sgPdf/Shereholding/fy17/6d2484a9aa_MSL+31.03.2017+SHAREHOLDING+PATTERN0001.pdf';
import fy17b from '../../assests/sgPdf/Shereholding/fy17/9954eab9fe_SHP+30.06.2016+MsL0001.pdf';


import fy16a from '../../assests/sgPdf/Shereholding/fy16/8c6a4cb02a_scan0021.pdf';
import fy16b from '../../assests/sgPdf/Shereholding/fy16/d42ae39a14_MSL-SHAREHOLDING0001.pdf';
import fy16c from '../../assests/sgPdf/Shereholding/fy16/512d67fe87_SHP+30.09.2015.compressed.pdf';
import fy16d from '../../assests/sgPdf/Shereholding/fy16/df4e162b30_SHAREHOLDING+-MSL-30.06.15.pdf';

import fy15a from '../../assests/sgPdf/Shereholding/fy15/661a6d10cd_Sahre+Holding+Pattern+31-03-20150001.pdf';
import fy15b from '../../assests/sgPdf/Shereholding/fy15/b0f3cbdc7d_Share_Hording_Pattern_31-12-20140001.pdf';
import fy15c from '../../assests/sgPdf/Shereholding/fy15/b74eb233d2_Shareholding_Pattern_30-09-20140001.pdf';
import fy15d from '../../assests/sgPdf/Shereholding/fy15/de921e5a32_Shareholding_Pattern_30-06-20140001.pdf';

import fy14a from '../../assests/sgPdf/Shereholding/fy14/3fe8d1d6b9_Shareholding_Pattern_31-03-20140001.pdf';
import fy14b from '../../assests/sgPdf/Shereholding/fy14/5114ae6bae_Shareholding_Pattern_31-12-20130001.pdf';
import fy14c from '../../assests/sgPdf/Shereholding/fy14/77c751dbe4_Shareholding_Pattern_30-09-20130001.pdf';
import fy14d from '../../assests/sgPdf/Shereholding/fy14/8cdb85559f_Shareholding_Pattern_30-06-20130001.pdf';




const Shereholding = () => {

    const [selectedOption, setSelectedOption] = useState("option");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Shareholding Pattern </h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Shareholding Pattern </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option">Fin. Year: 2025</option>
                                <option value="option1">Fin. Year: 2024</option>
                                <option value="option2">Fin. Year: 2023</option>
                                <option value="option3">Fin. Year: 2022</option>
                                <option value="option4">Fin. Year: 2021</option>
                                <option value="option5">Fin. Year: 2020</option>
                                <option value="option6">Fin. Year: 2019</option>
                                <option value="option7">Fin. Year: 2018</option>
                                <option value="option8">Fin. Year: 2017</option>
                                <option value="option9">Fin. Year: 2016</option>
                                <option value="option10">Fin. Year: 2015</option>
                                <option value="option11">Fin. Year: 2014</option>


                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                        {selectedOption === "option" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25} target='blank'>  <h6> Shareholding Pattern March 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25a} target='blank'>  <h6> Shareholding Pattern as on June 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25b} target='blank'>  <h6> Shareholding Pattern as on September 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25c} target='blank'>  <h6> Shareholding Pattern as on December 2024</h6> </a>
                                </div>
                                
                                




                            </>

                        )}
                        {selectedOption === "option1" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24} target='blank'>  <h6> Shareholding Pattern as on 31.12.2023</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24a} target='blank'>  <h6> Shareholding Pattern as on 31.03.2023</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24b} target='blank'>  <h6> Shareholding Pattern as on 30.06.2023</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24c} target='blank'>  <h6> Shareholding Pattern as on 30.09.2023</h6> </a>
                                </div>




                            </>

                        )}




                        {selectedOption === "option2" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23a} target='blank'>  <h6> Shareholding Pattern SGFIN 31.12.2022</h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23b} target='blank'>  <h6>Shareholding Pattern Post 10.12.2022 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23c} target='blank'>  <h6>Shareholding Pattern Pre 10.12.2022 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23d} target='blank'>  <h6>SHP MSL 30.09.2022 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23e} target='blank'>  <h6> Revised Post Shareholding Pattern 16.09.2022 Final</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23f} target='blank'>  <h6>Pre Shareholding Pattern 16.09.2022 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23g} target='blank'>  <h6>Share holding Pattern 30-06-2022 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23h} target='blank'>  <h6> Shareholding Pattern as on 31.03.2023</h6> </a>
                                </div>


                            </>
                        )}


                        {selectedOption === "option3" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22a} target='blank'>  <h6>Shareholding Pattern 31.03.2022 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22b} target='blank'>  <h6>shareholding Pattern 31.12.2021 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22c} target='blank'>  <h6>Shareholding Pattern 30.09.2021 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22f} target='blank'>  <h6>Share holding Pattern 30-06-2021 </h6> </a>
                                </div>


                            </>
                        )}

                        {/* 21 */}
                        {selectedOption === "option4" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21a} target='blank'>  <h6> Shareholding patter for the quarter ended March 31, 2021</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21b} target='blank'>  <h6>6f8e7b8252_MSL Shareholding pattern for Quater ended December 2020 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21c} target='blank'>  <h6>MSL Shareholding patter September 2020 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'>  <h6>Reg 31(1)(b) Shareholding Pattern For quater endedJune 2020 </h6> </a>
                                </div>




                            </>
                        )}

                        {/* fy 20 */}

                        {selectedOption === "option5" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20a} target='blank'>  <h6>Shareholding pattern for the Year Ended March 2020 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20b} target='blank'>  <h6>Shareholding Patteren-31-12-2019 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20c} target='blank'>  <h6>Shareholding Patteren-30-09-2019 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20d} target='blank'>  <h6>Shareholding Patteren-30-06-2019 </h6> </a>
                                </div>


                            </>
                        )}

                        {/* fy 19 */}

                        {selectedOption === "option6" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19a} target='blank'>  <h6> Shareholding Patteren-31-03-2019</h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19b} target='blank'>  <h6>Shareholding Patteren-31-12-2018 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'>  <h6>Shareholding Patteren-30-09-2018 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19d} target='blank'>  <h6>Share Holding Pattern 30.06.2018 </h6> </a>
                                </div>




                            </>
                        )}

                        {/* fy 18 */}

                        {selectedOption === "option7" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18a} target='blank'>  <h6>Share Holding Pattern 31.03.2018 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18b} target='blank'>  <h6>Share Holding Pattern 31.12.2017 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'>  <h6>Share Holding Pattern 30.09.2017 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18c} target='blank'>  <h6> Share Holding Pattern 30.06.2017</h6> </a>
                                </div>


                            </>
                        )}


                        {/* fy 17  */}


                        {selectedOption === "option8" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy17a} target='blank'>  <h6> Share Holding Pattern 31.03.2017</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'>  <h6> Share Holding Pattern 31.12.2016</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'>  <h6>shareholding pattern 30.9.2016 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy17b} target='blank'>  <h6> Share Holding Pattern 30.06.2016</h6> </a>
                                </div>



                            </>
                        )}


                        {/* fy 16 */}

                        {selectedOption === "option9" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16a} target='blank'>  <h6> Share Holding Pattern 31.03.2016</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16b} target='blank'>  <h6>Share Holding Pattern 31.12.2015 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16c} target='blank'>  <h6>Share Holding Pattern 30.09.2015 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16d} target='blank'>  <h6>Share Holding Pattern 30.06.2015 </h6> </a>
                                </div>


                            </>
                        )}


                        {/* fy 15  */}

                        {selectedOption === "option10" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy15a} target='blank'>  <h6>Share holding Pattern 31.03.2015 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy15b} target='blank'>  <h6>Share Holding Pattern 31.12.2014 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy15c} target='blank'>  <h6>Share Holding Pattern 30.09.2014 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy15d} target='blank'>  <h6>Share holding Pattern 30-06-2014 </h6> </a>
                                </div>


                            </>
                        )}



                        {/* fy 14 */}

                        {selectedOption === "option11" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy14a} target='blank'>  <h6>Share Holding Pattern 31.03.2014 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy14b} target='blank'>  <h6> Share Holding Pattern 31.12.2013</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy14c} target='blank'>  <h6>Share Holding Pattern 30.09.2013 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy14d} target='blank'>  <h6>Share holding Pattern 30-06-2013 </h6> </a>
                                </div>

                            </>
                        )}


                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}


export default Shereholding