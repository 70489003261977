import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileAudio } from "react-icons/fa";
import { Link } from 'react-router-dom';
import fy24_25a from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/2Qfy25 Investors call.pdf';
import fy24_25b from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/Reschduled Investor Call.pdf';
import fy24_25c from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/Transcript -23.10.2024.pdf';
import fy24_25d from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/Intimation Investor meet.pdf';
import fy24_25e from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/Q3FY25_investor call Invite.pdf';
import fy24_25f from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/10028923.mp3';
import fy24_25g from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/Transcript 17.01.2025.pdf';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ScheduleOfAnalyst = () => {

    const [selectedOption, setSelectedOption] = useState("option"); // State to track selected option
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Schedule of Analyst or Institutional Meet </h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Schedule of Analyst or Institutional Meet </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option">FY 2024-2025</option>
                                

                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* 24-25 */}

                        {selectedOption === "option" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25c} target='blank'>  <h6>Transcript -23.10.2024</h6> </a>
                                </div>
                                
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25a} target='blank'>  <h6>2Qfy25 Investors call</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25b} target='blank'>  <h6>Reschedule of Investor Call</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25d} target='blank'>  <h6>Intimation Investor Meet</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25e} target='blank'>  <h6>Q3FY25_investor call Invite</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25g} target='blank'>  <h6>Transcript 17.01.2025</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaFileAudio size={25} style={{ color: 'red' }} />
                                    <h6>Q3FY25 Earning Conference Call as on 17 Jan, 2025

                                </h6><a class="btn btn-info btncss"  onClick={handleShow1}>Play Audio</a>
                                </div>
                                <div className="pdf-clas">
                                    <FaFileAudio size={25} style={{ color: 'red' }} />
                                    <h6>Q1 Earnings Conference Call As On 17 Oct,2024 

                                </h6><a class="btn btn-info btncss"  onClick={handleShow}>Play Audio</a>
                                </div>
                                


                            </>

                        )}
                       



                    </div>
                </div>

            </section>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
        <audio class="audioclass" controls>
  <source src="https://sgpdf.s3.ap-south-1.amazonaws.com/sg-pdf/WhatsApp+Audio+2024-10-17+at+5.29.40+PM.mpeg" type="audio/mpeg" />
 
  
</audio></Modal.Body>
        
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
        <audio class="audioclass" controls>
  <source src={fy24_25f} type="audio/mpeg" />
 
  
</audio></Modal.Body>
        
      </Modal>


            <Footer />


        </>
    )
}

export default ScheduleOfAnalyst