import React, { useState, useEffect, useRef } from 'react';
import sorab from '../../assests/sD.webp';
import sahil from '../../assests/sahil.webp';
import abhishek from '../../assests/Team/abhishek mahajan.png';
import ankush from '../../assests/Team/ankush aggarwal.png';
import Arvind from '../../assests/Team/Arvind vstsa.png';
import Renu from '../../assests/Team/Renu Rai.png';
import Ritu from '../../assests/Team/Ritu Nagpal.png';
import vivekanand from '../../assests/Team/vivekanand.png';
import Prateek from '../../assests/Team/prateek.png';
import Sanjay from '../../assests/Team/Sanjay.png';
import '../../hamcss/team.css';
import { IoMdClose } from "react-icons/io";

const Team = () => {


    const [visible, setIsvisible] = useState(false)
    const [visible1, setIsvisible1] = useState(false)
    const [visible2, setIsvisible2] = useState(false)
    const [visible3, setIsvisible3] = useState(false)
    const [visible4, setIsvisible4] = useState(false)
    const [visible5, setIsvisible5] = useState(false)
    const [visible6, setIsvisible6] = useState(false)
    const [visible7, setIsvisible7] = useState(false)
    const [visible8, setIsvisible8] = useState(false)
    const [visible9, setIsvisible9] = useState(false)



    const boxRef = useRef(null);


    const handleOpen = () => {
        setIsvisible(!visible)
    }

    const handleOpen1 = () => {
        setIsvisible1(!visible1)
    }
    const handleOpen2 = () => {
        setIsvisible2(!visible2)
    }

    const handleOpen3 = () => {
        setIsvisible3(!visible3)
    }

    const handleOpen4 = () => {
        setIsvisible4(!visible4)
    }

    const handleOpen5 = () => {
        setIsvisible5(!visible5)
    }
    const handleOpen6 = () => {
        setIsvisible6(!visible6)
    }
    const handleOpen7 = () => {
        setIsvisible7(!visible7)
    }

    const handleOpen8 = () => {
        setIsvisible8(!visible8)
    }

    const handleOpen9 = () => {
        setIsvisible9(!visible9)
    }


    const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible1(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible2(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible3(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible4(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible5(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible6(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible7(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible8(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible9(false);
        }
    };

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);




    return (
        <>

            <section >

                <div className='main-team-box' >
                    <div style={{ textAlign: 'center', paddingBottom: '50px' }}>
                        <h2 style={{ fontWeight: '600', paddingBottom: '10px', color: 'white' }}>OUR  EXPERIENCED TEAM </h2>
                        {/* <h5 style={{ wordSpacing: '2px', wordBreak: 'break-all' }}>, as mentioned below: </h5> */}
                        <h5 style={{ paddingBottom: '10px', wordSpacing: '1px', wordBreak: 'break-all', letterSpacing: '0.2px', color: 'white' }}>The Company has appointed well-established board consisting of experienced & reputed industry leaders. Established all <br /> requisite  departments including Business, Credit, Risk Management, Operations,  Compliance,  Human  Resources  and <br /> Finance & Accounts.</h5>

                    </div>


                    <div style={{ display: 'flex', gap: '30px' }} >

                        <div className='main-team-box-inside '>


                            <div onClick={handleOpen} className='image-box' >
                                <img src={sorab} alt='Sorab Dhawan' className='image-box-team' />
                                <h6 className='p-text-team'>SORABH DHAWAN</h6>
                                <p style={{ margin: '0', fontWeight: '600', fontSize: '14px' }}>Chief Executive Officer</p>
                                <p style={{ margin: '0', textAlign: 'center',  fontSize: '14px' }}>17 years in  CorporateBanking, E-x Kotak,  HDFC & Aditya Birla Finance</p>
                            </div>



                            <div onClick={handleOpen1} className='image-box' >
                                <img src={abhishek} alt='Abhishek mahajan' className='image-box-team' />
                                <h6 className='p-text-team'>ABHISHEK MAHAJAN</h6>
                                <p style={{ margin: '0', fontWeight: '600', fontSize: '14px' }}>Chief Risk Officer</p>
                                <p style={{ margin: '0', textAlign: 'center',  fontSize: '14px' }}> 18 years in Credit CA- 2000,  Ex IDBI,  ICICI & Kotak</p>
                            </div>



                            <div onClick={handleOpen2} className='image-box' >
                                <img src={vivekanand} alt='VIVEKANAND TIWARI' className='image-box-team' />
                                <h6 className='p-text-team'>VIVEKANAND TIWARI</h6>
                                <p style={{ margin: '0', fontWeight: '600', textAlign: 'center', wordBreak: 'break-all', fontSize: '14px' }}>Head of Credit  Operations &  Monitoring</p>
                                <p style={{ margin: '0', textAlign: 'center',  fontSize: '14px' }}> 15 years in  Operations, Ex  ICICI, Axis & IndusInd</p>
                            </div>


                            <div onClick={handleOpen3} className='image-box' >
                                <img src={Renu} alt='RENU RAI' className='image-box-team' />
                                <h6 className='p-text-team'>RENU RAI</h6>
                                <p style={{ margin: '0', fontWeight: '600', wordBreak: 'break-all', fontSize: '14px' }}>Chief People Officer</p>
                                <p style={{ margin: '0', textAlign: 'center',  fontSize: '14px' }}>15 years in  HR & Admin,  Ex APL, Fedders  Lloyds  &  Advaitam Tech</p>
                            </div>


                            <div onClick={handleOpen4} className='image-box' >
                                <img src={sahil} alt='SAHIL SIKKA' className='image-box-team' />
                                <h6 className='p-text-team'>SAHIL SIKKA</h6>
                                <p style={{ margin: '0', fontWeight: '600', wordBreak: 'break-all', fontSize: '14px' }}>COO & CFO </p>
                                <p style={{ margin: '0', textAlign: 'center',fontSize: '14px' }}>15 years in  Corporate  Banking Ex HDFC, Kotak &  Aditya Birla</p>
                            </div>



                            <div onClick={handleOpen5} className='image-box' >
                                <img src={ankush} alt='ANKUSH AGGARWAL' className='image-box-team' />
                                <h6 className='p-text-team'>ANKUSH AGGARWAL</h6>
                                <p style={{ margin: '0', fontWeight: '600', wordBreak: 'break-all', fontSize: '14px' }}>Chief Experience Officer</p>
                                <p style={{ margin: '0', textAlign: 'center', fontSize: '14px' }}>17 years in  Client servicing,  Operations &  Digital  Transformation, Automation,  Ex-Kotak, IndusInd</p>
                            </div>



                            <div onClick={handleOpen6} className='image-box' >
                                <img src={Ritu} alt='RITU NAGPAL' className='image-box-team' />
                                <h6 className='p-text-team'>RITU NAGPAL</h6>
                                <p style={{ margin: '0', fontWeight: '600', fontSize: '14px' }}>Company Secretary &  Compliance  Officer </p>
                                <p style={{ margin: '0', textAlign: 'center', wordBreak: 'break-all', fontSize: '14px' }}>10 years in Compliance of  NBFC-SI & Non-SI</p>
                            </div>

                            {/*

                            <div onClick={handleOpen7} className='image-box' >
                                <img src={Arvind} alt='ARVIND VATSA' className='image-box-team' />
                                <h6 className='p-text-team'>ARVIND VATSA</h6>
                                <p style={{ margin: '0', fontWeight: '600', fontSize: '14px' }}>Chief Compliance Officer  </p>
                                <p style={{ margin: '0', textAlign: 'center', fontSize: '14px' }}>35 years in  Credit  Operations, Ex Barclays,
                                    Kotak, GE Capital, Standard  Chartered
                                </p>
                            </div>
                            */}
                            {/* Prateek Somani */}

                            <div onClick={handleOpen8} className='image-box' >
                                <img src={Prateek} alt='ARVIND VATSA' className='image-box-team' />
                                <h6 className='p-text-team'>PRATEEK SOMANI</h6>
                                <p style={{ margin: '0', fontWeight: '600', fontSize: '14px' }}>Head – Anchor Relations  </p>
                                <p style={{ margin: '0', textAlign: 'center',  fontSize: '14px' }}>10+ years in Corporate Finance  Ex SBICAPS, EY, ABFL, Kotak
                                </p>
                            </div>


                            {/* Sanjay Rajput */}

                            <div onClick={handleOpen9} className='image-box' >
                                <img src={Sanjay} alt='ARVIND VATSA' className='image-box-team' />
                                <h6 className='p-text-team'>SANJAY RAJPUT</h6>
                                <p style={{ margin: '0', fontWeight: '600', fontSize: '14px' }}>Head Finance and Accounts </p>
                                <p style={{ margin: '0', textAlign: 'center',  fontSize: '14px' }}>17 years in Finance and Accounting, Ex Livfin,  Airtel, Metropolis healthcare </p>
                            </div>





                        </div>

                        {/* sorabh dhawan */}

                        {
                            visible && (
                                <div>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen} className="close-icon" />

                                        <div style={{ textAlign: 'left' }} ref={boxRef}>

                                            <h6 className='p-text-team'>SORABH DHAWAN</h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Chief Executive Officer</p>
                                            <p className="p-text-team-para " >Sorabh Dhawan brings a wealth of experience and expertise to his role as Chief Executive Officer. Here's a summary of his profile:</p>
                                            <div>

                                                <p className="p-text-team-para " ><b className="p-text-team-para ">Professional Background:</b> With around 17 years of experience, Sorabh has worked with leading Banks and Financial Institutions in various leadership roles. He has extensive insight into Corporate Lending, spanning across functions including Business, Credit, Technology, and Operational initiatives.</p>
                                                <p className="p-text-team-para " > <b className="p-text-team-para ">Experience with Leading Institutions:</b> Sorabh has held key positions at renowned institutions such as Kotak Mahindra Bank, HDFC Bank, and Aditya Birla Finance. He has worked in wholesale lending verticals, focusing on SME, Mid Corporate, and Structured Finance solutions</p>

                                                <p className="p-text-team-para "><b className="p-text-team-para ">Track Record:</b> Sorabh has been instrumental in setting up departments from scratch in his previous roles, demonstrating strong leadership and strategic vision. He has consistently met organizational growth expectations while maintaining a high-quality portfolio, with overall underwriting exceeding USD 2 billion and a record of zero Non-Performing Assets (NPA).</p>

                                                <p className="p-text-team-para "> <b className="p-text-team-para ">Education:</b> He holds a master's degree in business administration from the University of Aberdeen, Scotland, UK, showcasing his academic qualifications and global perspective.</p>

                                                <p className="p-text-team-para ">  <b className="p-text-team-para ">Recognition and Awards:</b> Sorabh has received various awards and accolades throughout his career, including the "Best Team" award from Aditya Birla Finance Limited for FY 2018 and the "Best Manager" award from Kotak Mahindra Bank for FY 2020.</p>

                                                <p className="p-text-team-para ">  <b className="p-text-team-para ">Current Role:</b> As Chief Executive Officer, Sorabh is responsible for spearheading the overall functioning of the company. This includes overseeing Business, Credit, Operations & Technology, Compliance, and HR functions.</p>

                                                <p className="p-text-team-para ">Sorabh Dhawan's extensive experience, track record of success, leadership capabilities, and academic qualifications make him a valuable asset in driving the growth and success of the organization. His strategic vision, coupled with his ability to maintain high-quality standards, positions him as a trusted leader in the financial industry.</p>
                                            </div>

                                        </div>
                                        <button className="close-btn" onClick={handleOpen}>Close</button>

                                    </div>
                                </div>
                            )
                        }



                        {/* Abhishek mahajan */}

                        {
                            visible1 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen1} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Abhishek Mahajan </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Chief Risk Officer</p>
                                            <p className="p-text-team-para " >Abhishek Mahajan, our Chief Risk Officer, is a seasoned Chartered Accountant from the esteemed 2000 batch. With over 20 years of hands-on experience in the credit department, Abhishek has honed his skills through various roles at renowned institutions such as IDBI Bank, ICICI Bank Limited, and Kotak Mahindra Bank.</p>
                                            <p className="p-text-team-para ">His extensive background in risk assessment and management is a testament to his expertise in navigating the intricacies of financial landscapes. Abhishek's profound understanding of complex financial mechanisms enables him to provide invaluable insights, ensuring the stability and security of our organization's operations.</p>
                                            <p className="p-text-team-para ">With a track record of successfully mitigating risks and safeguarding the interests of the institution, Abhishek Mahajan is an indispensable asset to our team. His commitment to excellence and his ability to tackle challenges head-on make him an exemplary leader in the realm of risk management.</p>
                                        </div>
                                        <button className="close-btn" onClick={handleOpen1}>Close</button>

                                    </div>
                                </div>
                            )
                        }




                        {/* vivekanad Tiwari */}

                        {
                            visible2 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen2} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Vivekanand Tiwari  </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Head Operations</p>
                                            <p className="p-text-team-para "> Vivekanand Tiwari is a senior strategic leader and dynamic professional with over 12 years of extensive experience across various sectors including Banks, NBFCs, Fintech, and Startups. His expertise encompasses a wide range of areas such as Credit Administration & Operations, Customer Relationship Management, Product Development, Policy and Process Improvement, and Portfolio Management. </p>
                                            <p className="p-text-team-para "> With hands-on experience in setting up and leading entire credit administration and operations teams within NBFCs, Fintech companies, and startups, Vivekanand has demonstrated his ability to drive organizational success through effective leadership and operational excellence. His leadership skills have likely played a crucial role in shaping cohesive teams and achieving strategic objectives. </p>
                                            <p className="p-text-team-para "> Moreover, Vivekanand has been instrumental in supervising and implementing digitization and automation processes in both Banks and NBFCs/Fintech companies. This showcases his ability to leverage technology to streamline operations, enhance efficiency, and drive innovation within the organizations he has worked with. </p>
                                            <p className="p-text-team-para "> Having worked with renowned institutions such as ICICI Bank Limited, Axis Bank Limited, and Induslnd Bank, Vivekanand brings with him a wealth of experience and a deep understanding of the intricacies of the financial services industry. His track record of success and his ability to adapt to diverse environments make him a valuable asset in any operational leadership role. </p>
                                            <p className="p-text-team-para "> Overall, Vivekanand Tiwari's blend of strategic vision, operational expertise, and leadership acumen positions him as a highly capable Head of Operations capable of driving impactful change and delivering results in dynamic and challenging business environments. </p>
                                        </div>
                                        <button className="close-btn" onClick={handleOpen2}>Close</button>

                                    </div>
                                </div>
                            )
                        }


                        {/* RENU RAI
 */}


                        {
                            visible3 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen3} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Renu Rai   </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Chief People Officer</p>
                                            <p className="p-text-team-para "> Renu Rai, our Chief People Officer, brings a wealth of experience in HR and administrative functions spanning over 16 years. She has held key positions in notable companies such as APL Apollo, Fedders Lloyds, and Advaitam Tech, where she has honed her skills across various aspects of human resource management and administration. </p>
                                            <p className="p-text-team-para "> Throughout her career, Renu has demonstrated expertise in recruitment, employee relations, training and development, policy formulation, compliance, and organizational development. Her tenure at these esteemed organizations has provided her with valuable insights into building and nurturing a high-performing workforce. </p>
                                            <p className="p-text-team-para ">  Under Renu's leadership, the entire team at our organization has been handpicked and hired, shaping a dedicated and cohesive workforce. Her commitment to excellence resonates in every aspect of our thriving organization, reflecting her dedication to fostering a positive work environment and driving employee engagement</p>
                                            <p className="p-text-team-para "> With her extensive background and experience, Renu is well-equipped to handle diverse challenges within the HR and administrative domains. Her leadership skills likely include managing teams, implementing strategic initiatives, and driving organizational effectiveness. </p>
                                            <p className="p-text-team-para "> Moreover, Renu has gained proficiency in utilizing HRMS (Human Resource Management System) software and other technology tools to streamline processes and enhance efficiency. Her ability to leverage technology to optimize HR operations underscores her forward-thinking approach to human resource management. </p>
                                            <p className="p-text-team-para ">Overall, Renu Rai's comprehensive expertise, coupled with her commitment to fostering a supportive and productive work environment, makes her an invaluable asset to our organization. Her leadership ensures that our team continues to thrive and achieve excellence in all endeavours. </p>
                                        </div>
                                        <button className="close-btn" onClick={handleOpen3}>Close</button>

                                    </div>
                                </div>
                            )
                        }


                        {/*SAHIL SIKKA*/}

                        {
                            visible4 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen4} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Sahil Sikka   </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Chief Financial Officer & Chief Operating Officer</p>

                                            <p className="p-text-team-para ">Sahil Sikka brings extensive experience and a strong track record of success to his role as Chief Financial Officer and Chief Operating Officer. Here's a summary of his profile: </p>


                                            <p className="p-text-team-para "> <b className="p-text-team-para "> Professional Background:</b> With around 14 years of experience, Sahil has held leadership roles in leading Banks and Financial Institutions.  His expertise spans across Business and Credit in Corporate and Investment Banking. </p>

                                            <p className="p-text-team-para ">  <b >Experience with Leading Institutions:</b> Sahil has worked with renowned institutions such as HDFC Bank, Kotak Mahindra Bank, and Aditya Birla Finance.  He played a key role in setting up the Structured Lending Team and Book for Aditya Birla Finance, North, from scratch, demonstrating his strategic vision and leadership abilities.  </p>

                                            <p className="p-text-team-para "> <b className="p-text-team-para ">Education and Qualifications:</b> Sahil holds a Bachelor's degree in Engineering (B.Engg.), showcasing his technical background. He is also a Dean's Honor Student MBA graduate and has achieved the Chartered Financial Analyst (CFA) Level 2 certification, highlighting his academic achievements and financial expertise. </p>


                                            <p className="p-text-team-para ">     <b> Awards and Recognition:</b> Throughout his career, Sahil has received various awards and accolades, including the Young Achiever award at Aditya Birla Capital, Gold Award in HDFC Bank, and Team Excellence award in Aditya Birla Finance. He has also been recognized for his academic achievements, being the winner of the National Talent Search Examination and Mathematics Olympiad. </p>

                                            <p className="p-text-team-para ">   <b className="p-text-team-para ">Current Role:</b> As Chief Financial Officer and Chief Operating Officer, Sahil is responsible for spearheading the Business and Finance verticals of the company. This includes overseeing financial operations, strategic planning, and business development initiatives. </p>


                                            <p className="p-text-team-para ">Sahil Sikka's combination of extensive experience, academic achievements, and leadership capabilities make him a valuable asset in driving the success and growth of the organization. His strategic vision and commitment to excellence position him as a trusted leader in the financial industry.</p>




                                        </div>
                                        <button className="close-btn" onClick={handleOpen4}>Close</button>

                                    </div>
                                </div>
                            )
                        }

                        {/* Ankush agrawall */}

                        {
                            visible5 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen5} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Ankush Aggarwal   </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Chief Experience Officer</p>

                                            <p className="p-text-team-para ">Ankush Aggarwal, our Chief Experience Officer, brings over 17 years of dedicated experience in Client Servicing Operations and driving Digital Transformation initiatives. Throughout his career, Ankush has demonstrated a keen understanding of client needs and a commitment to enhancing customer experiences. </p>
                                            <p className="p-text-team-para ">Having held prominent positions in renowned financial institutions such as Kotak Mahindra Bank and Induslnd Bank, Ankush has honed his skills in managing client relationships and ensuring exceptional service delivery. His expertise in spearheading digital transformation initiatives has been instrumental in enhancing business processes and elevating customer experiences to new heights.  </p>
                                            <p className="p-text-team-para ">  <b className="p-text-team-para ">Key Expertise:</b> Client Relationship Management: Ankush has successfully managed client relationships, ensuring exceptional service delivery and satisfaction. His ability to understand client needs and tailor solutions accordingly has been pivotal in fostering long-term relationships.  Digital Transformation: Ankush has led and implemented transformative digital strategies aimed at enhancing business processes and customer experiences. His forward-thinking approach has enabled organizations to stay ahead in the ever-evolving digital landscape. Automation Initiatives: Ankush has pioneered automation initiatives, streamlining operations and reducing manual efforts. His efforts have resulted in significant cost savings and improved efficiency across various operational functions. </p>
                                            <p className="p-text-team-para "> <b className="p-text-team-para ">Professional Accomplishments:</b> Digital Transformation Roadmaps: Ankush has developed and executed comprehensive digital transformation roadmaps, resulting in notable enhancements in operational efficiency and customer engagement. His strategic vision has guided organizations towards embracing digital technologies effectively. Automation Solutions Implementation: Ankush has played a pivotal role in the implementation of cutting-edge automation solutions, leading to streamlined processes and heightened productivity. His hands-on approach and attention to detail have ensured successful outcomes in automation projects. Recognition for Operational Excellence: Ankush has received accolades for exemplary client servicing and operational excellence. His ability to consistently exceed performance benchmarks and KPIs highlights his dedication and contribution to organizational success.</p>
                                            <p className="p-text-team-para "> Ankush's wealth of knowledge and expertise make him a valuable asset to our team. His passion for innovation and commitment to delivering exceptional experiences align perfectly with our organizational goals, driving us towards continued success in the digital age</p>

                                        </div>
                                        <button className="close-btn" onClick={handleOpen5}>Close</button>

                                    </div>
                                </div>
                            )
                        }



                        {/* RITU NAGPAL */}


                        {
                            visible6 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen6} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Ritu Nagpal  </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Company Secretary & Compliance Officer</p>

                                            <p className="p-text-team-para "> Ritu Nagpal is a highly qualified and experienced professional with a diverse background in commerce, law, and company secretarial practice. Graduating with a Commerce degree from Delhi University, she furthered her qualifications by becoming a qualified Company Secretary in June 2014. Ritu pursued her post-graduation in M. Com with a specialization in Corporate Governance from IGNOU University in 2018. Additionally, she holds a Bachelor of Law degree from CCS Meerut University.</p>
                                            <p className="p-text-team-para ">With over 10 years of professional experience, Ritu has worked with prominent CA firms, listed companies, and NBFCs (Non-Banking Financial Companies) in India. Her expertise lies in various domains including Company Law Compliances, Secretarial Audit, RBI (Reserve Bank of India) related matters, and other regulatory affairs. </p>
                                            <p className="p-text-team-para ">Ritu's professional journey includes significant involvement in the incorporation of NBFCs, fintech companies, startups, as well as public and private limited companies. She has been instrumental in advising on a wide range of legal, compliance, and corporate governance matters, along with providing guidance on statutory and regulatory issues. </p>
                                            <p className="p-text-team-para "> Notably, Ritu Nagpal has contributed to the field through her writings, having authored numerous articles on topics such as RBI compliances, startups, MSMEs (Micro, Small, and Medium Enterprises), and FEMA-FDI (Foreign Exchange Management Act - Foreign Direct Investment). Her articles have been published by prominent institutions like ICSI (Institute of Company Secretaries of India) and others, reflecting her deep understanding and knowledge in these areas.</p>
                                            <p className="p-text-team-para "> Ritu's expertise extends to handling RBI compliances for various types of NBFCs, including Systemically Important (SI) and Non-Systemically Important (NSI) NBFCs, as well as Middle layer and Base layer NBFCs. Furthermore, she possesses extensive experience in SEBI (Securities and Exchange Board of India) LODR (Listing Obligations and Disclosure Requirements) compliances related to listed companies, including filing requirements on stock exchanges. Her proficiency also encompasses conducting internal audits for retail and manufacturing companies, highlighting her versatile skill set.</p>
                                            <p className="p-text-team-para "> Overall, Ritu Nagpal's comprehensive expertise, coupled with her dedication to staying abreast of regulatory developments, makes her a valuable asset in navigating the complex landscape of corporate compliance and governance.</p>

                                        </div>
                                        <button className="close-btn" onClick={handleOpen6}>Close</button>

                                    </div>
                                </div>
                            )
                        }

                        {/* ARVIND VATSA */}




                        {
                            visible7 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen7} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Arvind Mohan Vatsa </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Chief Compliance Officer</p>
                                            <p className="p-text-team-para "> Arvind Mohan Vatsa, our Chief Compliance Officer, boasts over three decades of invaluable experience in both Banks and Non-Banking Financial Companies (NBFCs). His extensive tenure in the industry has endowed him with a wealth of expertise spanning diverse domains, particularly in regulatory adherence and operational excellence. </p>
                                            <p className="p-text-team-para "> Throughout his illustrious career, Mr. Vatsa has consistently demonstrated exceptional proficiency in ensuring compliance with regulatory standards while maintaining operational efficiency. His broad background includes pivotal roles in Trade Finance Operations, Mid Office, Bank Operations, and Customer Services Units across various sectors such as Corporate Banking, Investment Banking, and Wealth Management. </p>
                                            <p className="p-text-team-para "> Mr. Vatsa's commitment to upholding the highest standards of compliance is unwavering. He is dedicated to driving organizational integrity and safeguarding regulatory compliance in every facet of our operations. His depth of knowledge and experience make him an invaluable asset to our team, ensuring that we navigate regulatory landscapes with confidence and integrity. </p>



                                        </div>
                                        <button className="close-btn" onClick={handleOpen7}>Close</button>

                                    </div>
                                </div>
                            )
                        }



                        {/* Prateek somani  */}


                        {
                            visible8 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen8} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Prateek Somani</h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Head- Anchor Relations</p>

                                            <p style={{ marginTop: '10px' }} className="p-text-team-para ">Prateek Somani is a dynamic and accomplished leader, currently serving as the head of Anchor Relationships for PAN India Corporates at our organization. With an impressive track record spanning 11 years, he has garnered valuable experience across diverse sectors including Investment Banking, Strategic Consulting, and Corporate Banking. </p>
                                            <p style={{ marginTop: '10px' }} className="p-text-team-para "> Throughout his career, Prateek has excelled in cultivating new partnerships and fortifying existing ones, leveraging his expertise in Efficient Portfolio Management, Business Relationship Management, and Banking. His professional journey includes stints at prestigious firms such as SBI Capital Markets Ltd., Ernst & Young, Aditya Birla Finance, and Kotak Bank, where he honed his skills and made significant contributions.</p>
                                            <p style={{ marginTop: '10px' }} className="p-text-team-para "> One of Prateek's key strengths lies in his adeptness at engaging with top-tier management within Indian Corporates, effectively understanding their needs, and offering tailored solutions. His hands-on experience in facilitating customized supply chain financing solutions has been instrumental in driving business growth and fostering long-term relationships with clients.</p>
                                            <p style={{ marginTop: '10px' }} className="p-text-team-para ">Prateek Somani's multifaceted background, coupled with his strategic acumen and commitment to excellence, positions him as a valuable asset to our organization's leadership team. He continues to play a pivotal role in driving innovation, expanding our network, and delivering unparalleled value to our corporate clientele across the country. </p>



                                        </div>
                                        <button className="close-btn" onClick={handleOpen8}>Close</button>

                                    </div>
                                </div>
                            )
                        }



                        {/* Sanjay Rajput */}


                        {
                            visible9 && (
                                <div style={{ textAlign: 'left' }}>
                                    <div className="pop-overlay"></div> {/* Modal overlay */}
                                    <div className="pop-box-team">
                                        <IoMdClose onClick={handleOpen9} className="close-icon" />
                                        <div ref={boxRef}>
                                            <h6 className='p-text-team'>Sanjay Rajput  </h6>
                                            <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Head Finance & Accounts</p>

                                            <p style={{ marginTop: '10px' }} className="p-text-team-para "> Sanjay Rajput, our Head of Finance & Accounts, brings over 17 years of dedicated expertise in the field of Finance & Accounts. As a Chartered Accountant, he has honed his skills and cultivated a wealth of experience in various capacities throughout his career.</p>
                                            <p style={{ marginTop: '10px' }} className="p-text-team-para ">Sanjay has made significant contributions to esteemed organizations such as NAFA Financial Controller and Livifin India Pvt. Ltd. His adeptness in financial management, strategic planning, and regulatory compliance sets him apart in the industry. His deep understanding of financial intricacies coupled with his strategic vision allows him to provide unparalleled guidance to businesses. </p>
                                            <p style={{ marginTop: '10px' }} className="p-text-team-para ">With a proven track record of delivering impactful solutions and ensuring fiscal integrity, Sanjay stands as a reliable ally for businesses seeking adept financial guidance. His commitment to excellence and his ability to navigate complex financial landscapes make him an invaluable asset to our team. Under his leadership, we can trust in the stability and growth of our financial operations. </p>

                                        </div>
                                        <button className="close-btn" onClick={handleOpen9}>Close</button>

                                    </div>
                                </div>
                            )
                        }





                    </div>


                </div>

            </section >


        </>
    );
}

export default Team;
