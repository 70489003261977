import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";

import FY24_25a from '../../assests/sgPdf/Financial Results/FY 2024-25/Outome-28.05.2024.f72794c5724b8571abd2.pdf'
import FY24_25b from '../../assests/sgPdf/Financial Results/FY 2024-25/Quarterly & Half Yearly Results of September 2024.pdf';
import FY24_25c from '../../assests/sgPdf/Financial Results/FY 2024-25/Q3 FY25 Results.pdf';
import FY24_25 from '../../assests/sgPdf/Financial Results/FY 2024-25/Disclsoure-33.pdf'
import FY23_24dec from '../../assests/sgPdf/Financial Results/i. FY 2023-24/a. Dec 2023/Outcome-19.01.2024.pdf'
import FY23_24sept from '../../assests/sgPdf/Financial Results/i. FY 2023-24/b. Sept 2023/Final Outcome-18.10.2023.pdf';
import FY23_24june from '../../assests/sgPdf/Financial Results/i. FY 2023-24/c. June 2023/15.07.2023-Outome-33.pdf'
import FY23_24march from '../../assests/sgPdf/Financial Results/i. FY 2023-24/d. March 2023/Outcome-final-11.05.2023.pdf'

import fy22_23june from '../../assests/sgPdf/Financial Results/ii. FY 2022-23/c. June 2022/Financial Result For Quarter Ended June, 2022.pdf';
import fy22_23sep from '../../assests/sgPdf/Financial Results/ii. FY 2022-23/b. Sept 2022/Unaudited Financial Results for quarter ended September 2022.pdf';
import fy22_23dec from '../../assests/sgPdf/Financial Results/ii. FY 2022-23/a. Dec 2022/Unaudited Financial Results for quarter ended Dec 2022.pdf';
import FY22_23march from '../../assests/sgPdf/Financial Results/ii. FY 2022-23/d. March 2022/audited financial statement yearly.pdf'
import FY21_22dec from '../../assests/sgPdf/Financial Results/iii. FY 2021-22/a. Dec 2021/quarter & 9 months ended.pdf';
import FY21_22sept from '../../assests/sgPdf/Financial Results/iii. FY 2021-22/b. Sept 2021/quarter & half yearly.pdf';
import FY21_22june from '../../assests/sgPdf/Financial Results/iii. FY 2021-22/c. June 2021/30.06.2021.pdf';
import FY21_22march from '../../assests/sgPdf/Financial Results/iii. FY 2021-22/d. March 2021/audited financial statement.pdf';
import FY20_21dec from '../../assests/sgPdf/Financial Results/iv. FY 2020-21/a. Dec 2020/Quarter & 9months.pdf';
import FY20_21sept from '../../assests/sgPdf/Financial Results/iv. FY 2020-21/b. Sept 2020/quarter & half year.pdf';
import FY20_21june from '../../assests/sgPdf/Financial Results/iv. FY 2020-21/c. June 2020/30.06.2020.pdf';
import FY20_21march from '../../assests/sgPdf/Financial Results/iv. FY 2020-21/d. March 2020/quarter & year ended.pdf';
import FY19_20dec from '../../assests/sgPdf/Financial Results/v. FY 2019-20/a. Dec 2019/quarter & 9months.pdf';
import FY19_20sept from '../../assests/sgPdf/Financial Results/v. FY 2019-20/b. Sept 2019/quarter & half year.pdf';
import FY19_20june from '../../assests/sgPdf/Financial Results/v. FY 2019-20/c. June 2019/30.06.2019.pdf';
import FY19_20march from '../../assests/sgPdf/Financial Results/v. FY 2019-20/d. March 2019/quarter & year ended.pdf';

import FY18_19dec from '../../assests/sgPdf/Financial Results/vi. FY 2018-19/a. Dec 2018/quarter & year ended.pdf';
import FY18_19sept from '../../assests/sgPdf/Financial Results/vi. FY 2018-19/b. Sept 2018/quarter & half year ended.pdf';
import FY18_19june from '../../assests/sgPdf/Financial Results/vi. FY 2018-19/c. June 2018/30.06.2018.pdf';
import FY18_19march from '../../assests/sgPdf/Financial Results/vi. FY 2018-19/d. March 2018/year ended 31.03.2018.pdf';


import fy17_18dec from '../../assests/sgPdf/Financial Results/FY 2017-18/31.12.2017.pdf'
import fy17_18sep from '../../assests/sgPdf/Financial Results/FY 2017-18/30.09.2017.pdf';
import fy17_18june from '../../assests/sgPdf/Financial Results/FY 2017-18/30.06.2017.pdf';
import fy17_18march from '../../assests/sgPdf/Financial Results/FY 2017-18/31.03.2018.pdf';
import fy16_17March from '../../assests/sgPdf/Financial Results/FY 2016-17/31.03.2017.pdf';


const FinancialResult = () => {



    const [selectedOption, setSelectedOption] = useState("option"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>
            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Financial Results</h2>
                    </div>
                </div>
            </section>

            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Financial Results</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option">FY 2024-25</option>
                                <option value="option1">FY 2023-24</option>
                                <option value="option2">FY 2022-23</option>
                                <option value="option3">FY 2021-22</option>
                                <option value="option4">FY 2020-21</option>
                                <option value="option5">FY 2019-20</option>
                                <option value="option6">FY 2018-19</option>
                                <option value="option7">FY 2017-18</option>
                                <option value="option8">FY 2016-17</option>
                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                        {selectedOption === "option" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25a} target='blank'> <h6>Results For March 2024</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25} target='blank'> <h6>Quarterly Results of June 2024</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25b} target='blank'> <h6>Quarterly & Half Yearly Results of September 2024</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY24_25c} target='blank'> <h6>Q3 FY25 Results</h6></a>
                                </div>
                                
                                
                            </>

                        )}
                        {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY23_24dec} target='blank'> <h6>December 2023</h6></a>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY23_24sept} target='blank'>  <h6>September 2023</h6></a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY23_24june} target='blank'>   <h6>June 2023</h6></a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY23_24march} target='blank'>   <h6>March 2023</h6></a>
                                </div>
                            </>

                        )}
                        {selectedOption === "option2" && (
                            <>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23dec} target='blank'><h6>December 2022</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23sep} target='blank'><h6>September 2022</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY22_23march} target='blank'><h6>June 2022</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY22_23march} target='blank'><h6>March 2022</h6> </a>
                                </div>

                            </>
                        )}
                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21_22dec} target='blank'><h6>December 2021</h6></a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21_22sept} target='blank'>  <h6>September 2021 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21_22june} target='blank'><h6>June 2021</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21_22march} target='blank'><h6>March 2021</h6> </a>
                                </div>
                            </>
                        )}


                        {selectedOption === "option4" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY20_21dec} target='blank'>  <h6>December 2020</h6></a>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY20_21sept} target='blank'><h6>September 2020 </h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY20_21june} target='blank'> <h6>June 2020</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY20_21march} target='blank'> <h6>March 2020</h6> </a>
                                </div>

                            </>
                        )}

                        {/* 19-20 */}

                        {selectedOption === "option5" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY19_20dec} target='blank'> <h6>December 2019</h6></a>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY19_20sept} target='blank'> <h6>September 2019</h6></a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY19_20june} target='blank'>  <h6>June 2019</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY19_20march} target='blank'>  <h6>March 2019</h6> </a>
                                </div>
                            </>
                        )}

                        {selectedOption === "option6" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY18_19dec} target='blank'> <h6>December 2018</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY18_19sept} target='blank'> <h6>September 2018</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY18_19june} target='blank'> <h6>June 2018</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY18_19march} target='blank'> <h6>March 2018</h6> </a>
                                </div>

                            </>
                        )}

                        {/* 17-18 */}


                        {
                            selectedOption === "option7" && (
                                <>
                                    <div className="pdf-clas-2">
                                        <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                        <a href={fy17_18dec} target='blank'> <h6>December 2017</h6> </a>
                                    </div>

                                    <div className="pdf-clas-2">
                                        <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                        <a href={fy17_18sep} target='blank'> <h6>September 2017</h6> </a>
                                    </div>

                                    <div className="pdf-clas-2">
                                        <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                        <a href={fy17_18june} target='blank'> <h6>June 2017</h6> </a>
                                    </div>

                                    <div className="pdf-clas-2">
                                        <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                        <a href={fy17_18march} target='blank'> <h6>March 2017</h6> </a>
                                    </div>
                                </>
                            )

                        }

                        {
                            selectedOption === "option8" && (
                                <>
                                    <div className="pdf-clas-2">
                                        <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                        <a href={fy16_17March} target='blank'> <h6>March 2016</h6> </a>
                                    </div>
                                </>
                            )

                        }



                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}


export default FinancialResult